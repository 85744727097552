<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="applicationDescriptions"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <!-- name -->
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.vacancy`]="{item}">
        {{ item.vacancy }}
      </template>

      <template #[`item.phoneNumber`]="{item}">
        {{ item.phoneNumber }}
      </template>

      <template #[`item.email`]="{item}">
        {{ item.email }}
      </template>

      <!-- date -->
      <template #[`item.dateOfBirth`]="{item}">
        <span class="text-no-wrap">{{ toDate(item.dateOfBirth) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'vacancy-application-form', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Просмотр</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiEye,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { toDateSting, hasPermission } from '@/helpers/index'

export default {
  props: ['vacancyApplicationStatus'],
  name: 'ApplicationDescriptionList',
  data: () => ({
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiEye,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
  }),
  computed: {
    ...mapFormHandlers('vacancyApplication', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.statusId',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('vacancyApplication', {
      applicationDescriptions: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Вакансия', value: 'vacancy' },
        { text: 'Номер телефона', value: 'phoneNumber' },
        { text: 'Почта', value: 'email' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  mounted() {
    this.filters.statusId = this.vacancyApplicationStatus
    this.fetchVacancyApplications();
  },
  methods: {
    ...mapActions('vacancyApplication', {
      fetchVacancyApplications: 'fetchList',
    }),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    toDate(val){
      return toDateSting(val);
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
