<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      @change="changed"
    >
      <v-tab v-for="(item, index) in vacancyApplicationStatusList" :key="index">
        <span>{{item.name}}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in vacancyApplicationStatusList" :key="index">
        <vacancy-application-list :vacancy-application-status="item.id"></vacancy-application-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import VacancyApplicationList from './vacancy-application-list.vue'
import { mapFormHandlers } from '@/helpers/forms'
export default {
  name: 'VacancyApplicationTab',
  components: {VacancyApplicationList},
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapState('dictionary', {
      vacancyApplicationStatusList: 'vacancyApplicationStatusList',
    }),
    ...mapFormHandlers('vacancyApplication', ['filters.statusId']),
  },
  methods: {
    ...mapActions('dictionary', ['fetchVacancyApplicationStatuses']),
    changed(val){
      this.filters.statusId = this.vacancyApplicationStatusList[val].id
    }
  },
  async created(){
    await this.fetchVacancyApplicationStatuses();
  }
}
</script>
