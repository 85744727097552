var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"promotion-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"promotion-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Поиск..."},model:{value:(_vm.filters.searchQuery),callback:function ($$v) {_vm.$set(_vm.filters, "searchQuery", $$v)},expression:"filters.searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.applicationDescriptions,"server-items-length":_vm.totalCount,"loading":_vm.loading,"page":_vm.filters.pageNumber,"items-per-page":_vm.filters.pageSize,"sort-by":_vm.filters.sortBy,"sort-desc":_vm.filters.sortDesc},on:{"update:page":function($event){return _vm.$set(_vm.filters, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "pageSize", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.vacancy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vacancy)+" ")]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phoneNumber)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.dateOfBirth",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.toDate(item.dateOfBirth)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'vacancy-application-form', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }